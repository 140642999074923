html,
body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url('../src/images/background.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

button,
a {
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'dela';
  src: local('dela'),
    url('./fonts/DelaGothicOne-Regular.ttf') format('ttf'),
    url('./fonts/DelaGothicOne-Regular.woff') format('woff'),
    url('./fonts/DelaGothicOne-Regular.woff2') format('woff2');
}